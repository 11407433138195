import MainLayout from "../../examples/LayoutContainers/MainLayout";
import React, { useState } from "react";

import _ from "lodash";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import MainNavbar from "../../examples/Navbars/MainNavbar";
import TabPanel, { a11yProps } from "components/TabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import SoftBox from "components/SoftBox";
import Blacklist from "./components/Blacklist";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Whitelist from "./components/Whitelist";

const CustomersManagement = (props) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <MainLayout>
      <MainNavbar />
      <SoftBox sx={{ maxWidth: 400 }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ backgroundColor: "transparent" }}
            variant="standard"
          >
            <Tab icon={<CheckCircleOutlineIcon />} label="WhiteList" {...a11yProps(0)} />
            <Tab icon={<DoNotDisturbIcon />} label="BlackList" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
      </SoftBox>
      <TabPanel value={value} index={0}>
        <Whitelist />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Blacklist />
      </TabPanel>
    </MainLayout>
  );
};

export default CustomersManagement;
